.container_plan{padding: 0 7px;}
.catalogue_plan_panel {margin: 0 0 20px;}
.catalogue_plan_panel .pic {
	position: relative;
	display: inline-block;
}
.catalogue_plan_panel .pic a:HOVER{
	text-decoration: none;
	color: #000;
}

.catalogue_plan_panel .descro-petit{
	padding: 5px 0 0 10px; 
}

.catalogue_plan_panel .descro-petit, .catalogue_plan_panel .descro-petit p, .catalogue_plan_panel .descro-petit div{
	overflow: hidden;
}
.catalogue_plan_panel .descro-petit div{
}

.zone_detail-favori{
	position: absolute;
	font-size: 0;
	bottom: 9%;
	right: 5%;
}

.label-pic-info{
	position: absolute;
	top:0;
	right: 0;
}

.zone-etoile-favori{
	padding: 8px 4px 9px 8px;
	border: none;
	text-decoration: none;
}

.catalogue_plan_panel .text{
	padding: 0px 10px 10px 10px;
}

.catalogue_resultat{
	float:left;
	margin-right:8px;
	width:227px
}	

.catalogue_resultat #modeles_similaires{
	float: right;
	padding-top: 55px;
}

#catalogue_plan_panel img{
	border: none;
	padding:0px;
	width:219px;
	height:146px
}

.zone-promo{
	bottom: 9%;
	position: absolute;
	left: 5%
}

/* Landscape + Mini resoltion */
@media (max-width: 550px) {
	.descro-petit{display: none;}
}