@font-face {font-family: roboto-condensed;src: url("../font/Roboto-Condensed.ttf")}
@font-face {font-family: "roboto-condensed-light";src: url("../font/RobotoCondensed-Light.ttf")}
.roboto-light{font-family: "roboto-condensed-light"}
@font-face {font-family: "roboto-condensed-bold";src: url("../font/RobotoCondensed-Bold.ttf")}
.roboto-bold{font-family: "roboto-condensed-bold"}
@font-face {font-family: "roboto-condensed-regular";src: url("../font/RobotoCondensed-Regular.ttf")}
.roboto-regular{font-family: "roboto-condensed-regular"}
.arial{font-family: "Arial"}
.belina{font-family: fantasy}

.important-block{
	display: block !important;
}

.uppercase{
	text-transform: uppercase
}

.overflow-auto{
	overflow: auto
}

.no-padding{
	padding: 0;
}

.no-margin{
	margin: 0;
}

.no-padding-left{
	padding-left:0
}

.padding-left-15{
	padding-left:15px
}

.padding-left-5{
	padding-left:5px
}

.padding-left-30{
	padding-left:30px
}

.no-padding-right{
	padding-right:0
}

.padding-right-15{
	padding-right:15px
}

.padding-haut-10{
	padding-top:10px
}

.padding-haut-20{
	padding-top:20px
}

.padding-haut-30{
	padding-top:30px
}

.padding-haut-40{
	padding-top:40px
}

.padding-bottom-10{
	padding-bottom:10px !important
}

.padding-bottom-20{
	padding-bottom:20px !important
}

.padding-bottom-30{
	padding-bottom:30px !important
}

.padding-10{
	padding:10px
}

.padding-30{
	padding:30px
}

.margin-haut-10{
	margin:10px 0
}

.margin-bottom-15{
	margin-bottom:15px
}

.margin-bottom-10{
	margin-bottom:10px
}

.marge-basse-responsive-30{
	margin-bottom:30px
}

.marge-top-responsive-30-15{
	margin-top: 30px
}

.hauteur-responsive-50-40{
	height: 50px
}

.font-default-size{
	font-size:14px;
	font-weight:normal
}

br{
	clear:both
}

hr{
	margin:10px 0;
	border-color:#BBB
}

hr.petit{
	margin:5px 0
}

a, a:HOVER, a:FOCUS{
	text-decoration: none;
	text-decoration: underline;
	color: #000;
}
.en-ligne{
	display: inline
}

.en-block{
	display: block
}

.souligne{
	text-decoration: underline
}
.pas-souligne, a.pas-souligne{
	text-decoration: none
}

.gras{
	font-weight: bold
}

.clear{
	clear: both
}

.clear-right{
	clear: right
}

.block-center{
	margin-left: auto;
	margin-right: auto
}

a.no-border{
	border-bottom: none;
	text-decoration: none
}

.fond-noir{
	background-color: black
}

.fond-blanc{
	background-color: white
}

.fond-degrade{
	background: url("../img/header_bg_degrade.gif");
	background-repeat: repeat-x
}

.fond-blue{
	background-color: #25A8DF
}

.fond-rose{
	background-color: #F1817F
}
	
.fond-opaque-blanc{
	background: rgba(255,255,255,0.8)
}

.blanc{
	color: white
}

a.blanc:HOVER{
	color: white
}

.gris-pale{
	color: #D0D2D3
}

.gris-moyen-pale{
	color: #A6A8AB
}

.gris-moyen{
	color: #929497
}
.gris-moyen-plus{
	color: #808080
}

.gris-1{
	color: #6D6E70
}

.gris-2{
	color: #646564
}

.gris-fonce{
	color : #58595B
}
.rouge-2,#footer a.rouge-2{
	color: #E40042
}

.noir{
	color: #000
}

.rose{
	color: #FF9898
}

.rouge{
	color: #BD223C
}

a.noir:hover{
	color: #000
}

.enorme{
	font-size: 35px
}

.hyper-enorme{
	font-size: 48px
}

.enorme-moins{
	font-size: 34px
}

.hyper-gros, .hyper-gros-resp{
	font-size: 28px
}

.gros{
	font-size: 23px
}

.moyen-gros{
	font-size: 20px
}

.moyen{
	font-size: 18px
}

.moyen-petit{
	font-size: 16px
}

.petit{
	font-size: 14px
}

.plus-petit{
	font-size: 13px
}

.tres-petit{
	font-size: 12px
}

.super-petit{
	font-size: 10px
}

.mini-petit{
	font-size: 8px
}

.grosse-marge-bas{
	margin-bottom: 40px;
}
.relatif{
	position: relative;
}
.elt-haut-droit{
	position: absolute;
	right: 0;top: 0;
}

.elt-bas-gauche{
	position: absolute;
	left: 0;
	bottom: 0;
}

.padding-15-20{
	padding: 15px 20px
}

.padding-right-15{
	padding-right: 15px
}

.margin-hauteur-10{
	margin: 10px 0
}

.pointer{
	cursor: pointer
}

ul.short{padding-left: 20px;}

.btn_action, .zone-rouge, .ui-dialog .ui-dialog-titlebar{
	padding: 8px 11px 9px;
	border: none;
  	color: rgba(255,255,255,1);
	background: -webkit-linear-gradient(-90deg, #E50043 0, #B02B2E 100%);
  	background: -moz-linear-gradient(180deg, #E50043 0, #B02B2E 100%);
  	background: linear-gradient(180deg, #E50043 0, #B02B2E 100%);
  	text-shadow: 0 1px 0 rgba(0,0,0,0.3) ;
  	text-decoration: none;
}

.btn_action-gris, .zone-gris{
	padding: 8px 11px 9px; border: none;color: rgba(255,255,255,1);
	background: -webkit-linear-gradient(-90deg, #86949C 0, #616E75 100%);
  	background: -moz-linear-gradient(180deg, #86949C 0, #616E75 100%);
  	background: linear-gradient(180deg, #86949C 0, #616E75 100%);
  	text-shadow: 0 1px 0 rgba(0,0,0,0.3) ;
  	text-decoration: none;
}

.btn_action, .btn_action-gris{
	cursor: pointer;
	text-transform : uppercase
}

a.btn_action:VISITED, a.btn_action:HOVER, a.btn_action:FOCUS{
	color: #FFF;
	text-decoration: none
}

a.btn_action:HOVER{
	color: rgba(255,255,255,1);
	text-shadow: 0 1px 0 rgba(0,0,0,0.3) ;
	text-decoration: none
}

.btn_primary{
	-webkit-border-radius: 4px;
	border-radius: 4px;
	font: normal 14px/normal Arial, Helvetica, sans-serif
}

.btn_secondary{
  cursor: pointer;
  padding: 6px 8px;
  border: 1px solid #E6E7E8;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  font: normal 14px/normal Arial, Helvetica, sans-serif;
  color: rgba(0,0,0,1);
  background: -webkit-linear-gradient(-90deg, rgba(255,255,255,1) 0, #F1F1F2 100%);
  background: -moz-linear-gradient(180deg, rgba(255,255,255,1) 0, #F1F1F2 100%);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0, #F1F1F2 100%);
  text-shadow: 0 1px 0 rgba(252,253,255,1) ;
}

.arrondi-gauche{
	-webkit-border-radius: 4px 0 0 4px;
	border-radius: 4px 0 0 4px;
}

.arrondi-droit{
	-webkit-border-radius: 0 4px 4px 0;
	border-radius: 0 4px 4px 0;
}

.arrondi{
	-webkit-border-radius: 0 4px 4px 0;
	border-radius: 4px 4px 4px 4px
}

.titre-gris{
	font-family: "roboto-condensed";
	color: #929497
}
.titre-gras{
	font-family: "roboto-condensed-bold"
}

.fond-gris-1{
	background-color: #404041
}
.fond-gris-2{
	background-color: #2A2A2A
}

.fond-gris-3{
	background-color: #F7F7F7
}

.fond-gris-4{
	background-color: #FAFAFA
}

.fond-gris-5{
	background: #D0D2D3
}

.fond-rouge{
	background-color: #e50043
}

.white-box{
	text-transform: uppercase; 
	padding:3px; 
	border: 1px solid
}

.info {
	padding: 7px;
	background: #DDD;
	color: #000
}

.info-generale {
	padding: 20px;
	font-size: 17px;
	font-weight: bold;
	line-height: 20px;
	margin: 10px 0;
	background: #EBFFEB;
	color: #009933;
}

.load-col{
	opacity: 0.4;
}

h2.gros-titre, h1.gros-titre, .gros-titre{
	font-size: 26px;
	font-family: roboto-condensed-light;
	color: #6D6E70;
	text-transform : uppercase;
	font-weight: normal;
}

h2.noir{
	color: #000;
}

textarea, input, button, select {
	background-color: #f5f5f5;
	border: 1px solid #dddddd;
	border-radius: 2px;
}

.border-box, .small-box-border{
	border: 1px solid #aaa;
	padding: 20px;
	overflow: auto
}

.small-box-border{
	padding: 10px
}

.gratuit{
	background: #BD223C;
	color: white;
	padding:0 5px
}

#header{
	padding-top: 20px
}

#menu-principal{
	position: relative;
	z-index: 97;
}

.menu_on{
  background: -webkit-linear-gradient(90deg, #58595B 0, #000000 100%);
  background: -moz-linear-gradient(0deg, #58595B 0, #000000 100%);
  background: linear-gradient(0deg, #58595B 0, #000000 100%);
}

.navbar{
	min-height: inherit;
	margin-bottom: 0;
	border: none;
}

.navbar-default{
	background-color: inherit;
	border-color: inherit;
}

.navbar-default .navbar-toggle {
	border-color: #ddd;
    background: black;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: #FFF;
}

.navbar-collapse{
	max-height: inherit;
	padding-left: 0;
	background: -webkit-linear-gradient(-90deg, #E50043 0, #B02B2E 100%);
  	background: -moz-linear-gradient(180deg, #E50043 0, #B02B2E 100%);
  	background: linear-gradient(180deg, #E50043 0, #B02B2E 100%);
}

.navbar-nav li a{
	line-height: inherit;
}

#navigation .menu_over{
   color: #000;
   background: #FFF;
   border-left: solid 2px #DDD;
   border-top: solid 2px #DDD;
   border-right: solid 2px #DDD;
}

#navigation .menu_over a{
	color: #000
}

#navigation{
	padding-left: 0;
	margin: 0
}

#navigation li {
	list-style-type: none;
	height: 45px;
	border-right: solid 1px white;
	line-height: 26px;
	text-align: center;
}

#navigation li#li-socio {
	float:none; 
	overflow:hidden
}

#navigation a {
	font-size: 21px;
	font-family: roboto-condensed-light;
	color: white
}

#navigation .feed a:hover{
	text-decoration: underline
}

#navigation li a {
	padding: 0;
	text-decoration: none;
	border: none;
}

.navbar-toggle{
	margin:0;
}

#menu-xl{
	z-index: 96;
	width: 100%;
	position: absolute;
	margin-top: -2px
}

.sous-menu-xl{
	background-color: #FFF;
	border-top: solid 2px #DDD;
	box-shadow: 0px 5px 8px #888888;
	margin: 0 15px;
	padding:10px 15px; 
	overflow: auto;
}

#menu-xl .sous-menu-xl li a{
	color: #BD223C;
	font-family: "Arial";
	text-decoration: underline
}

.border-permis{ 
	border: 2px solid #DDD;
	padding: 2px
}

#panier{
	width: 60%;
	float: right;
}
#panier-titre{
	z-index: 99;
	position: relative
}

#panier-paiement-securise{
	position:absolute;
	font-style:italic;
	right: 0;
	padding-top: 5px
}

#panier-titre.deplie{
	background: #FFF;
	color: #000;
	border: 1px solid #aaa;
	border-bottom: none;
	box-shadow:0px -2px 1px #DDD;
	-webkit-border-radius: 0 0 0 0;
	border-radius: 0 0 0 0
}

#panier-main-zone{
	position: absolute;
	z-index: 98;
	width: 500px;
	right: 0;
	margin-top: -2px;
	box-shadow: 0px 2px 2px #DDD
}

.flag-disable{
	opacity: 0.4; 
	filter: alpha(opacity=40)
}

.pastille_promo{
  width:130px;
  height:130px;
  border-radius:65px;
  background : #E50043;
  -webkit-box-shadow: 0 0 10px 0 rgba(252,253,255,1) ;
  box-shadow: 0 0 10px 0 rgba(252,253,255,1) ;
  border-style : Solid;
  border-color : #FFFFFF;
  border-width : 2px;
}

.promo{
	background: url("../img/header_menu_bg_promo.jpg") no-repeat;
	height: 228px
}

.pro-menu{
	background: url("../img/header_menu_bg_offre_pro.jpg") no-repeat;
	height: 228px
}

.jcarousel{
	width: 630px
}

.jcarousel,.carousel-video{
	overflow: hidden;
	position: relative
}

.carousel-video ul , .jcarousel ul{
	position: relative;
	list-style: none;
	margin: 0;padding: 0
}

.jcarousel ul{
	width: 3150px
}

.jcarousel li, .carousel-video li{
	float: left
}

.jcarousel-pagination a{
	text-decoration: none
}

ul.carousel-masque li{
	background-image: url("../img/home_carrousel_masque_degrade.png")
}

.visio {
	height: 286px;
	position: relative;
	width: 630px
}

.text-visio{
	text-shadow: 0 1px 0 rgba(0,0,0,0.3) ;
	background: rgba(0,0,0,0.3);
	color: white;
	font-family: "roboto-condensed-bold";
	font-size: 35px;
	padding-left: 10px;
	text-transform: uppercase;
}

#li-accueil{
	width: 70px
}

.compteur-accueil{
	margin-top: 10px;
}

.compteur-accueil  span.compteur{
	font-size: 45px;
	color: #FFF;
	background: #000;
	padding: 2px 3px;
	background-image: linear-gradient(to top,#000 10%,#DDD);
	border-radius: 5px;
	font-family: Helvetica,Arial,sans-serif;
	font-weight: 600;
/* 	float: left; */
	margin-right: 1px;
}

.carre-rassure{
	padding: 10px 5px;
	margin: 13px 7px 0;
	text-align: center;
	width: 45%;
	font-family: "roboto-condensed-regular";
	height: 128px
}

.carre-rassure img{
	display: inline
}

.jcarousel-prev, .jcarousel-prev-mini{left: 0;}
.jcarousel-next, .jcarousel-next-mini{right: 0;}
.jcarousel-prev, .jcarousel-next{position: absolute;top: 42%;}
.jcarousel-pagination{position: absolute;bottom: 10px;left: 42%;}
.jcarousel-pagination a{background: url("../img/home_carrousel_pastille.png") no-repeat;display: inline-block;width: 20px;}
.jcarousel-pagination a.active{background: url("../img/home_carrousel_pastille_active.png") no-repeat;}
.carousel-video{width: 240px;}
.carousel-video ul{width: 720px;position: relative;list-style: none;margin: 0;padding: 0;}
.jcarousel-prev-mini, .jcarousel-next-mini{position: absolute;}

.icone-service{
	padding: 10px
}

.titre-service, .titre-plan, .titre-plan-pro, .titre-service a{
	font-family: "roboto-condensed-bold";
	font-weight: normal;
	color: #FFF ;
	background-color: #000;
	text-decoration: none;
}
.titre-plan{
	font-size: 22px;
	padding: 5px 10px
}

.titre-plan-pro{
	font-size: 22px;
	padding: 0 30px;
	height: 80px
}

.titre-plan-pro .text-pro{
	position: relative
}

.contenu-service{
	font-family: Arial;
	font-weight: normal;
	font-size: 14px;
	color: #FFF ;
	background-color: #58595B;
	padding: 8px
}

.service-plus{
	margin: 25px 0;
	padding: 15px 0;
	overflow: auto
}

ul.offre-pro{
	padding-left: 10px
}

ul.offre-pro li{
	margin: 25px 0
}

.imageMaison img{
	margin-right: 5%
} 

.permisImageMaison img{
	margin-right:1%
}

.devisPermisDroite{
	width: 50%;
	float: left
}

.devisPermisGauche {
	float: left;
	margin-right: 15%;
	text-align: right;
	width: 35%;
	margin-bottom: 15px
}

#questionDevisPermis select{
	padding: 2px;
	width: 177px
}

#questionDevisPermis input[type="text"] {
	padding: 2px;
	width: 177px
}

#panel-login-register.ui-tabs .ui-tabs-panel{
	border:1px solid #aaaaaa
}

.contactbox label {
    display: block;
    float: left;
    margin-bottom: 10px;
    margin-right: 10px;
    padding-right: 20px;
    text-align: right;
    width: 25%;
}
.contactbox input[type="text"], .contactbox input[type="password"], .contactbox input[type="email"] {width: 35%;}

.fond-gris-panier{
	background-color: #636467;
	padding: 6px 18px;
	color: white;
	margin-bottom: 9px;
	font-weight: bold
}

.rightKel{
	float: right;
	padding-right: 17px
}	

#optionEnvoi{
	overflow: auto;
	margin: 20px 0
}

#footer{
	margin-top: 15px
}

#footer a{
	color: #FFF
}

.prix-barre{
	text-decoration: line-through
}

.infoPaiement {
	padding: .8em;
	color: #515720;
	border: 1px solid #AAA;
	overflow: auto
}

.thumbnail {
    background-color: inherit;
    border: inherit;
    border-radius: inherit;
    display: inherit;
    line-height:inherit;
    margin-bottom: 0;
    padding: 0;
    transition: inherit;
}

.ui-tabs .ui-tabs-nav {margin: 0;padding: 0}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus {
    background-color: #e50043 ;
    border-color: #e50043;
}
.pagination > li > a, .pagination > li > span{color: #000;border: 1px solid #999;}
.pagination > .disabled > span, .pagination > .disabled > span:hover, .pagination > .disabled > span:focus, .pagination > .disabled > a, .pagination > .disabled > a:hover, .pagination > .disabled > a:focus {
    border-color: #999;
    color: #000;
}

.ui-slider .ui-state-default{background: url("/img/btn_slider.png") no-repeat;}
.ui-dialog-titlebar-close{background: url("/img/croix_fermer.png") no-repeat;}

#dialog-inscription{background: #FFF;}

/* ****** RESPONSIVE ****** */
/* Medium Desktop */
@media (max-width: 1200px) {
	#navigation a {font-size: 18px;}
	.hyper-gros, .hyper-gros-resp{font-size: 22px;}
	.gros{font-size: 19px}
}

/* Pour toutes les resolutions < 991px */
@media (max-width: 991px) {
	.align-droit-responsive {float: right}
	.marge-haute-responsive {margin-top: 15px}
	
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 991px) {
	#navigation a {font-size: 16px;}
	.hyper-gros, .hyper-gros-resp{font-size: 17px;}
	.gros{font-size: 16px}
	#panier{width:30%}
}
 
/* Landscape phone to portrait tablet */
@media (max-width: 767px) {
	#navigation a {font-size: 15px;}
	.hyper-gros{font-size: 17px;}
	.gros{font-size: 15px}
	.navbar-collapse{ padding-right:0}
	#navigation li{ border-right: none}
	.hyper-gros-resp{font-size: 25px;}
	#panier{width:40%}
	.marge-basse-responsive-30 {margin-bottom: 0}
	.marge-top-responsive-30-15{margin-top: 15px}
	.hauteur-responsive-50-40{height: 40px}
	.marge-haute-responsive-sm {margin-top: 15px}
	.guide-construct {font-size: 12px}
	.center-resp{text-align: center}
	#panier-main-zone {width : 300px}
	.padding-bottom-10-resp-xs{padding-bottom:10px}
}


/* Landscape + Mini+ resoltion -*/
@media (max-width: 600px) {
	.blanc-fond-xs-600{background: #FFF}
	.hyper-gros-resp{font-size: 17px;}
}
 
/* Landscape + Mini resoltion -- */
@media (max-width: 500px) {
	#navigation a {font-size: 15px;}	
	.hyper-gros{font-size: 17px;}
	h2.gros-titre, h1.gros-titre, .gros-titre{font-size: 20px;}
}

/* ********************* */